.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.about {
  /*
  font-family: Inter, sans-serif !important;
  */
}

.splashVid {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-size: cover;
  opacity: 0.60;
  overflow: hidden;
}

.splashPage:before{
  content: '';
  position: absolute;
  background: rgba(203, 137, 62, 0.5);
  border-radius: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.projectHeaderWrap{
  /*
  background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(88, 80, 57, 0.73));
  */
}

.projectImg {
  width: 100%;
  height: 100%;
  /*
  background-size: cover;
  */
  opacity: 0.90;
  overflow: hidden;
  position: absolute;
  left: 0;
  z-index: 0;
}

.avatar {
  border-radius: 50%;
  width: 180px;
  height: 180px;
  border: 5px solid #e3cfa1;
  box-shadow: 0px 0.5px 0px 0px gray;
  opacity: 0;
  animation: opacAboutAnim 5s ease forwards 1.0s;
}

@keyframes opacAboutAnim{
  from {
    opacity: 0%
  }

  to {
    opacity: 100%;
  }
}


.MuiSpeedDial-fab {
  background-color: #53859e !important;
  opacity: 0.8;
}


.menuButton:hover {
  filter: grayscale(50%);
}

.css-m9glnp-MuiPaper-root-MuiDialog-paper{
  background-color: rgb(255, 249, 235) !important;
}

.css-1hyl1h2 {
  background-color: rgb(255, 249, 235) !important;
}

.enter-button {
  animation: pulse 2.0s infinite;
}

@keyframes pulse {
  from {
    box-shadow: 0 0 0 0.01rem rgba(0, 0, 0, 0.15);
  }

  to {
    box-shadow: 0 0 0 0.30rem rgba(0, 0, 0, 0.00);
  }
}

.enter-button:hover {
  animation: none;
}


/* Below CSS typewriter effect settings adapted from here: https://css-tricks.com/snippets/css/typewriter-effect/ */
.line {
  position: relative;
  color: #242424;
  top: 50%;
  width: 24em;
  margin: 0 auto;
  border-right: 2px solid rgba(255,255,255,.75);
  font-family: -apple-system, Roboto, serif;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}

/* Animation */
.anim-typewriter{
  animation: typewriter 4s steps(32) 1s 1 normal both,
  blinkTextCursor 1000ms steps(32) infinite normal;
}

@keyframes typewriter{
  from{width: 0;}
  to{width: 15.0em;}
}

@keyframes blinkTextCursor{
  from{border-right-color: #242424;}
  to{border-right-color: transparent;}
}

/* Below CSS typewriter effect settings adapted from here: https://dev.to/afif/the-css-scrabble-writer-the-next-gen-typewriter-fbi */

.scrabble {
  display: inline-flex;
  text-transform:uppercase;
  position:relative;
  font-weight:bold;
  border: 5px solid;
  border-width: 0 5px 5px 0;
  border-image: linear-gradient(45deg,#0000 6px,#d0cfcd 0 calc(100% - 0.707*(1ch + 4px + 1em)),#b1b1b1 0 calc(100% - 6px),#0000 0) 5;
}
.scrabble + .scrabble {
  margin-left: calc(1ch + 1em - 7px)
}
.scrabble span {
  position:relative;
  white-space:nowrap;
  overflow:hidden;
  line-height: calc(1ch + 1em);
  clip-path: inset(0 calc(.5em - 2px) 0 0);
  margin-right: calc(2px - .5em);
  letter-spacing: 1em;
  background:
          linear-gradient(#dedddb 0 0) bottom/100% 2px no-repeat,
          conic-gradient(from 90deg at 2px 2px,#0000 90deg,#dedddb 0)
          0 0/calc(1ch + 1em) 100%,
          #f6f4f0;
  padding-left: .5em;
}

.scrabble span:before {
  content: "2 7 3 1 1 4 3 2 1 4 6 2 1 3 2 2 7 1 1";
  position: absolute;
  bottom: 9px;
  left: calc((1ch + 1em)/.35 - 1.5ch);
  letter-spacing: calc(1ch + 1.08em);
  font-size: 0.35em;
  line-height: 0;
  color: #848484;
}
.animate span{
  animation:t calc(.5s*2) steps(2) forwards;
  width:calc(2*(1ch + 1em));
}

.scrabble.animate:before {
  content: "";
  position: absolute;
  top:50%;
  transform:translateY(-60%);
  width: calc(1ch + 1.2em);
  height: calc(1ch + 1.8em);
  background:
          linear-gradient(#000 0 0) 18% 45%/1.5px 20%,
          radial-gradient(circle closest-side,#fff 98%,#0000) 0 50%/30% 50%,
          radial-gradient(circle closest-side,#fff 98%,#0000) 25% -3%/20% 50%,
          radial-gradient(circle closest-side,#fff 98%,#0000) 50% 10%/20% 50%,
          radial-gradient(circle closest-side,#fff 98%,#0000) 75% 27%/20% 50%,
          radial-gradient(circle closest-side,#fff 98%,#0000) 100% 48%/20% 50%,
          linear-gradient(#fff 0 0) 25% 100%/20% 75%,
          linear-gradient(#fff 0 0) 50% 100%/21% 69%,
          linear-gradient(#fff 0 0) 75% 100%/20% 60%,
          linear-gradient(#fff 0 0) 0 65%/100% 21%,
          linear-gradient(to bottom right,#fff 50%,#0000 0) 100% 100%/22% 29%,
          linear-gradient(to bottom left,#fff 50%,#0000 0) 0 100%/21% 29%;
  background-repeat: no-repeat;
  z-index: 1;
  left:100%;
  animation:d .5s calc(.5s*2) forwards;
  filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black);
}

@keyframes t {
  from {width:0}
}
@keyframes d {
  to {opacity:0}
}

.scrabbleBox {
  font-size: 45px;
  display:flex;
  margin:0;
  font-family: monospace;
  padding:calc(1ch + 1em - 5px) calc(1ch + 1em - 5px);
  background:conic-gradient(from 90deg at 2px 2px,#0000 90deg,#fff 0)
  0 0/calc(1ch + 1em) calc(1ch + 1em) #e3cfa1
}

/*===========================*/

/**{
  scrollbar-width: thin;
  scrollbar-color: #e3cfa1 #fcfcfc;
}

*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: #fcfcfc;
}
*::-webkit-scrollbar-thumb {
  background-color: #e3cfa1;
  border-radius: 15px;
  border: 2px solid #e3cfa1;
}*/
